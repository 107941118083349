.skills__title h2 {
    color: var(--color-primary);
    text-align: center;
    font-size: 2.5rem;
    margin-top: -4rem;
    padding: 3rem;
}

.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.skills__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.skills__container > div:hover {
    background:  transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.skills__container > div h3 {
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skills__details {
    display: flex;
    gap: 1rem;
}

.skills__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

/* ================ Media Queries (Medium Devices) =========== */
@media screen and (max-width: 1024px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__container >div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .skills__content {
        padding: 1rem;
    }
}

/* ================ Media Queries (Small Devices) =========== */
@media screen and (max-width: 600px) {
    .skills__container {
        gap: 1rem;
    }

    .skills__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}