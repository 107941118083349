.projects__title h2 {
    color: var(--color-primary);
    text-align: center;
    font-size: 2.5rem;
    margin-top: -4rem;
    padding: 3rem;
}

.projects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.projects__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.projects__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.projects__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

/* .projects__item h3 {
    margin: 1.2rem 0 2rem;
} */

.projects__item h3 {
    margin: 1.2rem 0 0.1rem;
    font-size: 20px;
}

.projects__item h5 {
    /* margin: 0 1.5rem 2rem; */
    margin-bottom: 2rem;
    font-size: 15px;
    color: var(--color-light);
}

.projects__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
}

/* ================ Media Queries (Medium Devices) =========== */
@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================ Media Queries (Small Devices) =========== */
@media screen and (max-width: 600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}