.about__title h2 {
    color: var(--color-primary);
    text-align: center;
    font-size: 2.5rem;
    margin-top: -4rem;
    padding: 3rem;
}

.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    font-weight: 500;
    justify-content: left;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-bg-variant), transparent);
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius:2rem;
    overflow: hidden;
    transform: rotate(7deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    /* background: black; */
    /* border: 1px solid transparent; */
    border-radius: 0.7rem;
    padding: 0.05rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__card-image {
    border-radius: 0.5rem;
    overflow: hidden;
    transition: 400ms;
}
.about__card-image:hover {
    border-radius: 0.5rem;
    transform: scale(1.5);
    overflow: hidden;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.5rem;
    font-size: 1.1rem;
    font-weight: 300;
    color: var(--color-light);
}

/* ================ Media Queries (Medium Devices) =========== */
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* ================ Media Queries (Small Devices) =========== */
@media screen and (max-width: 600px) {
    .about__title h2 {
        margin-top: -10rem;
        padding: 3rem;
    }

    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .cta {
        justify-content: center;
    }

    .about__title {
        margin-top: -9rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.3rem;
    }

    .about__card:hover {
        border-color: black;
    }

    .about__card-image:hover {
        transform: none;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}