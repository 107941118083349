header {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    padding: 3rem;
    position: relative;
}

/* ============= CTA ============= */
/* .cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    font-weight: 500;
    justify-content: center;
} */

/* ============= Header Socials ============= */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}

/* ============= ME ============= */
.me {
    /*background: linear-gradient(var(--color-primary), transparent);*/
    background: var(--color-primary);
    width: 22rem;
    height: 22rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 6rem;
    /*border-radius:12rem 12rem 0rem 0rem;*/
    border-radius: 50%;
    overflow: hidden;
    border: 0.7rem solid var(--color-primary-variant);
    /*padding: 5rem 1.5rem 1.5rem 1.5rem;*/
}

/* ============= Scroll Down ============= */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
}

/* ================ Media Queries (Medium Devices) =========== */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* ================ Media Queries (Small Devices) =========== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        padding-top: 17px;
    }

    .header__container h1 {
        font-size: 2.2rem;
    }

    .me {
        margin-top: 2.5rem;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}